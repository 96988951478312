.Main {
    padding: 0.5em;
    display: grid;
    grid-template-rows: 1fr 1fr 40px;
    grid-gap: 0.5em;
    max-height: 180px !important;
    height: 180px !important;
    min-height: 180px !important;
}

.ResourceEdit {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    grid-template-columns: 40px 50px 1fr 50px 40px;
}

.ResourceEdit button {
    padding: 0 !important;
    min-width: 40px !important;
}

.QtyDisplay {
    display: grid;
    place-content: center;
    font-size: 1.3em;
}

.Submit {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    place-content: center;
}

@media (max-width: 1000px) {
    .Main {
        padding: 0.25em;
        grid-template-rows: 1fr 1fr 20px;
        grid-gap: 0.25em;
        max-height: 90px !important;
        height: 90px !important;
        min-height: 90px !important;
    }

    .ResourceEdit {
        grid-template-columns: 20px 25px 1fr 25px 20px;
    }

    .ResourceEdit button {
        min-width: 20px !important;
    }

    .QtyDisplay {
        font-size: 0.8em;
    }

    .Submit {
        grid-gap: 0.5em;
    }

    .Submit > button {
        max-width: 32px !important;
        padding: 3px 8px !important;
    }

    .Submit > button * {
        font-size: 0.7em !important;
    }
}

.Main {
    display: grid;
    grid-template-rows: 50px 50px 1fr;
    grid-gap: 1em;
}

.Header {
    display: grid;
    grid-auto-flow: column;
    place-content: space-between;
    padding: 0.5em 2em;
}

.Header > div {
    display: grid;
    place-content: center start;
    grid-auto-flow: column;
    grid-gap: 1em;
}

.Content {
    display: grid;
    height: calc(100vh - 120px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 120px); /* Mobile Layout Calculation */
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 2em;
    place-content: start stretch;
}

.Item {
    display: grid;
    padding: 2px 8px;
    grid-auto-flow: column;
    place-content: center start;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(300px, 2fr);
    min-height: 32px;
    max-height: 32px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.Item:hover {
    background-color: rgba(255, 165, 0, 0.15);
}

.Item > * {
    padding: 0 4px;
}

.EmailPreviewWrp {
    margin: 4px 0;
    padding: 0 12px;
    border: 4px solid #999;
    border-radius: 8px;
    background-color: #f9f9f9;
}

@media print {
    .Main {
        grid-template-rows: 50px 1fr;
    }
}

.Main {
    padding: 0 !important;
    display: grid;
    place-content: stretch;
    /* TODO: wait for it to become an issue, case in which it should still be "below select menus" but on top of "global loader" */
    /* z-index: 10001; */
}

.Main button {
    padding: 0 !important;
    min-width: 36px;
    height: 100%;
    border-radius: 0;
}

.DialogMain {
    /* TODO: wait for it to become an issue, case in which it should still be "below select menus" but on top of "global loader" */
    /* z-index: 10000; */
}

.DialogActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    place-items: center space-between;
}

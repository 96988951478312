.Add {
    display: grid;
}

.Add button {
    margin: 0 !important;
    padding: 0 !important;
}

.Add span {
    color: gray;
    font-style: italic;
}

.AddDialogContent {
    display: grid;
}

.AddDialogInput {
    display: grid;
    grid-gap: 0.5em;
    grid-auto-flow: column;
    padding: 0.5em;
    grid-template-columns: 100px 1fr;
}

.AddDialogInputLabel {
    text-align: left;
}

.AddDialogActions {
    display: grid !important;
    grid-auto-flow: column;
    place-content: center;
    grid-gap: 1em;
    width: 100%;
}

.AddDialogTitle {
    display: grid;
}

/* Depth: 0 */
.Main {
    display: grid;
    grid-template-rows: 50px min-content 48px;
    height: calc(100vh - 36px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 36px); /* Mobile Layout Calculation */
    padding: 0 1em;
}

.ContentWrp {
    display: grid;
    height: calc(100vh - 36px - 60px - 4em); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 36px - 60px - 4em); /* Mobile Layout Calculation */
}

/* Depth: 1 */
.ToolbarWrp {
    display: grid;
}

.FooterWrp {
    display: grid;
    padding: 0 4px;
    place-content: center stretch;
}

.Footer {
    display: grid;
    grid-auto-flow: column;
    place-content: start space-between;
    grid-template-columns: min-content 1fr min-content;
    padding: 4px 0 0 0;
}

.Footer > div {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    grid-gap: 0.25em;
}

.Footer button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-radius: 0 !important;
}

.Content {
    display: grid;
    grid-template-rows: 50px 1fr;
}

.Content > .RowWrp {
    background-color: transparent !important;
}

.Toolbar {
    display: grid;
    grid-auto-flow: column;
    padding: 0 0.5em;
    place-content: center space-between;
}

/* Depth: 3 */
.ToolbarSelection {
    display: grid;
    min-width: 100px;
    max-width: 180px;
    place-content: center start;
}

.ToolbarSearch {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr min-content;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    min-width: 100px;
    max-width: 180px;
}

.ToolbarSearch:hover {
    border-bottom: 2px solid;
}

.ToolbarSearchInput {
    display: grid;
    color: #dddddd;
}

.ToolbarSearchInput input {
    padding: 0 !important;
    font-size: 0.9em !important;
}

.ToolbarSearchBtn {
    display: grid;
    padding: 0 !important;
    min-width: 40px;
}

.ToolbarFilter {
    display: grid;
    place-content: center end;
}

.HeaderWrp {
    margin-right: 15px;
    border-bottom: 2px solid gray;
}

.Header {
    display: grid;
}

.Header > * {
    place-content: center !important;
    margin: 0;
}

.HeaderSort {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: min-content 1fr;
    grid-gap: 2px;
    line-height: 20px;
}

.HeaderSort:hover {
    background-color: #dcedc8;
}

.HeaderSortIcon {
    display: grid;
    place-content: center;
}

.Filter {
    display: grid;
}

.Filter input {
    padding: 0 0.5em;
    font-size: 1.2em;
    background-color: transparent;
    border: none;
    text-align: right;
}

.ContentItems {
    display: grid;
    height: calc(100vh - 198px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 198px); /* Mobile Layout Calculation */
    place-content: start stretch;
    overflow: auto;
    overflow-y: scroll;
}

@media print {
    .ContentItems {
        height: calc(100vh - 234px + 48px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 234px + 48px); /* Mobile Layout Calculation */
    }
}

/* Depth 4+: */
.Header.Row {
    padding: 0;
}

.Header.Row span {
    font-weight: 600;
}

.RowWrp {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr min-content;
    width: calc(100vw - 48px - 4em); /* Layout Calculation */
}

.Row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 70px 1fr;
}

.RowWrp:nth-child(2n + 1) {
    background-color: white;
}

.RowWrp:nth-child(2n) {
    background-color: #eeeeee;
}

.ContentItems form.Row {
    background-color: lightsalmon;
}

.ItemActions {
    width: 70px;
    display: grid;
    place-content: center;
    grid-auto-flow: column;
    grid-gap: 0.5em;
    padding: 0 10px;
}

.ContentItems .ItemActions {
    /*border-right: 1px solid #ddd;*/
}

.ItemContent {
    display: grid;
    grid-auto-flow: column;
}

.ItemContent > * {
    padding: 0.4em 0.4em 0.4em 0.8em;
    border-right: 1px solid #dddddd;
}

.Header .ItemContent > * {
    border: none;
    padding: 0 0.25em 0 0.75em;
}

.ItemContentInput {
    display: grid;
    place-content: center;
}

.ItemContentInput > * {
    text-align: center;
}

.ToolbarForm {
    display: grid;
}

.ItemsCount {
    display: grid;
    place-content: center;
    font-size: 0.8em;
    font-weight: 300;
    padding: 0 0.25em;
    min-width: 60px;
}

.OrderSaveBtn {
    display: grid;
    place-content: stretch;
}

.OrderSaveBtn button {
    padding: 0 !important;
    margin-left: 6px;
    min-width: 30px !important;
}

.Handle {
    display: grid;
    place-content: center;
    min-width: 50px;
}

@media (max-width: 1000px) {
    .Main {
        grid-template-rows: 30px min-content 30px;
    }

    .Main {
        width: calc(100vw - 2em - 48px); /* Layout Calculation */
        height: calc(100vh - 2em - 48px - 64px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 2em - 48px - 64px); /* Mobile Layout Calculation */
    }

    .Main > * {
        width: calc(100vw - 2em - 48px); /* Layout Calculation */
    }

    .Content {
        grid-template-rows: 35px 1fr;
    }

    .ToolbarSearch {
        font-size: 0.8em;
    }

    .ToolbarFilter button {
        padding: 0 !important;
    }

    .ToolbarForm * {
        font-size: 0.9em !important;
    }

    .ToolbarSearchInput input {
        font-size: 0.8em !important;
    }

    .ContentWrp {
        height: calc(100vh - 36px - 50px - 2em); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 36px - 50px - 2em); /* Mobile Layout Calculation */
    }

    .ContentItems {
        height: calc(100vh - 150px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 150px); /* Mobile Layout Calculation */
    }

    .Header.Row span {
        font-size: 0.8em;
    }

    .ItemContent {
        font-size: 0.7em;
    }

    .RowWrp {
        width: calc(100vw - 4em - 48px); /* Layout Calculation */
    }

    .Row {
        grid-template-columns: 40px 1fr;
    }

    .ItemActions {
        padding: 0;
        grid-gap: 2px;
        place-content: start;
    }

    .ItemActions button {
        padding: 0;
    }

    .Handle {
        min-width: 40px;
    }
}

@media (max-width: 500px) {
    .Main {
        height: calc(100vh - 36px - 64px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 36px - 64px); /* Mobile Layout Calculation */
        width: calc(100vw - 2em); /* Layout Calculation */
    }

    .Main > * {
        width: calc(100vw - 2em); /* Layout Calculation */
    }

    .ContentItems {
        height: calc(100vh - 120px - 64px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 120px - 64px); /* Mobile Layout Calculation */
    }

    .ContentWrp {
        height: calc(100vh - 36px - 50px - 64px - 0em); /* Layout Calculation */
        height: calc(
            var(--vh, 1vh) * 100 - 36px - 50px - 64px - 0em
        ); /* Mobile Layout Calculation */
    }

    .RowWrp {
        width: calc(100vw - 2em - 24px); /* Layout Calculation */
    }

    .Handle {
        min-width: 30px;
    }
}

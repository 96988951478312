.menusToolbar {
    display: grid;
    grid-template-rows: 1fr min-content;
    padding: 0 2em;
}

.menusToolbarSelection {
    display: grid;
    grid-auto-flow: column;
    place-content: center space-between;
}

.menusToolbarSelection > * {
    min-width: 100px;
}

.menusToolbarReadFailures {
    display: grid;
    place-content: center;
}

.menusToolbarReadFailuresContent {
    display: grid;
    grid-gap: 0.25em;
    padding: 2px;
}

.menusToolbarReadFailure {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #cccccc;
}

.menusToolbarSelectionRestaurant {
    display: grid !important;
    grid-auto-flow: column;
    place-content: center start;
}

.menusToolbarCompletion {
    display: grid;
    grid-auto-flow: column;
    place-content: center end;
}

.menusToolbarCompletion > * {
    font-size: 1.3em;
    font-weight: 600;
}

@media (max-width: 1000px) {
    .menusToolbarSelection {
        min-height: 32px;
        height: 32px;
    }

    .menusToolbarCompletion * {
        margin: auto;
    }
}

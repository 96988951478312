.Content {
    display: grid;
    align-content: start;
    height: calc(100vh - 78px - 50px - 30px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 78px - 50px - 30px); /* Mobile Layout Calculation */
    overflow-y: scroll;
    margin: 0 1px;
}

.Size {
    display: grid;
    place-content: center;
    grid-auto-flow: column;
    grid-gap: 0.5em;
}

.SizeStatus-INVALID {
    background-color: #999;
}

.SizeStatus-NOT_STARTED {
    color: #c62828;
    background-color: #ffcdd2;
}

.SizeStatus-COMPLETED {
    color: #689f38;
    background-color: #dcedc8;
}

.SelectionRestaurant {
    min-width: 120px !important;
}

.ModeSwitch {
    background-color: white;
}

.NA {
    background-color: #eeeeee !important;
}

.Status {
    display: grid;
    place-content: center;
    height: 100%;
}

.Status.Selected {
    grid-auto-flow: column;
    grid-gap: 8px;
    font-size: 1.2em;
}

.StatusInvalid {
    color: #c62828;
    background-color: #ffcdd2;
}

.StatusNeedsOnHand {
    color: #9e9e9e;
    font-style: italic;
    font-size: 0.8em;
}

.StatusNeedsStocking {
    display: grid;
    font-size: 0.9em;
    color: black;
}

.StatusCompleted {
    color: #689f38;
}

.MasterInventory {
    display: grid;
    place-content: center;
}

.MasterInventoryLabel {
    display: grid;
}

.MasterInventory.Selected .MasterInventoryLabel {
    grid-auto-flow: column;
    grid-gap: 0.5em;
    font-size: 1.2em;
}

.InventorySelected {
    display: grid;
}

@media print {
    .Content {
        height: calc(100vh - 78px - 90px + 64px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 78px - 90px + 64px); /* Mobile Layout Calculation */
        overflow: unset;
    }
}

@media (max-width: 1000px) {
    .StatusNeedsOnHand {
        font-size: 0.6em;
        padding: 0 4px 0 2px;
    }

    .StatusNeedsOnHand > span {
        text-align: center;
    }

    .MasterInventory * {
        font-size: 0.7em !important;
    }
}

@media (max-width: 500px) {
    .Content {
        height: calc(100vh - 78px - 50px - 30px - 40px); /* Layout Calculation */
        height: calc(
            var(--vh, 1vh) * 100 - 78px - 50px - 30px - 40px
        ); /* Mobile Layout Calculation */
    }
}

.Selection {
    display: grid;
    place-content: stretch;
    min-width: 100px;
}

.Selection div {
    font-size: 0.9em !important;
}

.Main {
    display: grid;
    padding: 0 !important;
}

.MenuItem {
    padding: 0 !important;
    margin: 0 !important;
    display: grid !important;
    grid-auto-flow: column;
    place-content: stretch;
    min-height: auto;
}

.MenuItemHidden {
    display: none !important;
    max-height: 0px !important;
}

.FormControl {
    width: 100%;
    padding: 0 1em;
    margin: 0 !important;
    display: grid !important;
    grid-auto-flow: column;
    grid-template-columns: 2em 5em;
}

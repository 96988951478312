.Items {
    width: 100%;
    height: calc(100vh - 36px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 36px); /* Mobile Layout Calculation */
    padding: 1em;
    display: grid;
    grid-auto-flow: row;
    background-color: #f5f5f5;
    grid-template-rows: min-content 1fr;
    grid-gap: 1em;
}

.Item {
    display: grid;
    grid-gap: 1px;
    margin-bottom: 1px;
    grid-template-columns: minmax(min-content, 120px) repeat(auto-fit, minmax(40px, 1fr));
    grid-auto-flow: column;
}

.Item > :first-child {
    display: grid;
    place-content: center;
    font-weight: 600;
    text-align: center;
}

.Item > * {
    display: grid;
    min-height: 3em;
    background-color: white;
}

.Item > button {
    background-color: white !important;
    border-radius: unset !important;
}

.Item > .NameSelected {
    background-color: #8bc34a !important;
    color: white !important;
}

.ItemsContent {
    display: grid;
    height: calc(100vh - 78px - 50px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 78px - 50px); /* Layout Calculation */
    overflow-y: hidden;
    background-color: #cccccc;
}

.Toolbar {
    height: 50px;
    display: grid;
    place-content: center space-between;
    grid-auto-flow: column;
}

@media print {
    .Items {
        height: calc(100vh - 56px + 48px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 56px + 48px); /* Mobile Layout Calculation */
    }
}

@media (max-width: 1000px) {
    .Items {
        grid-gap: 0.5em;
        padding: 0.5em;
    }

    .Item {
        grid-template-columns: minmax(min-content, 60px) repeat(auto-fit, minmax(30px, 1fr));
    }

    .Item > :first-child {
        font-weight: normal;
        font-size: 0.7em;
        padding: 0 4px 0 2px;
    }
}

@media (max-width: 500px) {
    .Items {
        height: calc(100vh - 36px - 40px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 36px - 40px); /* Mobile Layout Calculation */
    }

    .ItemsContent {
        height: calc(100vh - 78px - 50px - 50px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 78px - 50px - 50px); /* Layout Calculation */
    }

    .Item {
        grid-template-columns: minmax(min-content, 40px) repeat(auto-fit, minmax(20px, 1fr));
    }
}

.Main {
    height: 36px;
    z-index: 100;
    background-color: #3f50b6;
    display: grid;
    grid-auto-flow: column;
    place-content: stretch center;
    grid-template-columns: min-content min-content 1fr;
    /* TODO: wait for it to become an issue, case in which it should still be "below select menus" but on top of "global loader" */
    /* z-index: 10000; */
}

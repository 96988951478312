.Label {
    font-size: 0.6em;
    font-weight: 300;
}

.Display {
    min-width: 2em;
}

@media (max-width: 500px) {
    .Label {
        font-size: 0.5em;
    }

    .Display {
        min-width: 2em;
    }
}

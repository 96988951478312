.Main {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(50px, 1fr);
    min-width: 200px;
    min-height: 100px;
    background-color: #eeeeee;
}

.Title,
.Actions {
    display: grid !important;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    place-content: center;
    justify-content: center !important;
    background-color: #eeeeee;
    padding: 8px 24px !important;
}

.Actions :not(:first-child) {
    margin: 0 !important;
}

.Day {
    display: grid !important;
    grid-auto-flow: column;
    min-width: 260px !important;
    width: 100%;
    grid-template-columns: 100px 1fr;
    grid-gap: 8px;
    padding: 0 16px 0 0;
    justify-content: unset !important;
    place-content: center end;
    grid-template-columns: 1fr min-content;
}

.DayNav {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 28px 24px 28px;
    grid-auto-flow: column;
}

.DayNav button {
    min-width: 20px !important;
}

.Day > div {
    display: grid;
    place-content: center;
}

.Day > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.DayPicker {
    max-width: 160px !important;
    min-width: 160px !important;
}

.DayPicker *,
.DayPicker *::before {
    color: #c5cae9 !important;
    border-color: #c5cae9 !important;
}

.Day input {
    font-size: 0.9em;
    min-width: 100px;
}

.Day button {
    padding: 0 !important;
}

@media (max-width: 1000px) {
    .Day {
        grid-gap: 4px;
        padding: 0;
        width: auto !important;
    }

    .DayNav {
        grid-template-columns: 1fr 1fr 1fr;
        place-content: center;
        padding: 0;
    }

    .DayNav button {
        min-width: 10px !important;
    }

    .DayPicker {
        place-content: unset !important;
        padding: 0 !important;
        margin-right: 0.5em;
    }

    .Day input {
        text-align: right;
        font-size: 0.8em !important;
        width: calc(100% - 50px);
        min-width: 50px;
        margin-right: 4px;
    }
}

.Main {
    display: grid;
}

.Main > * {
    display: grid;
    padding: 0.5em;
}

.Details {
    display: grid;
    grid-template-rows: 1fr 6fr 2fr 1fr;
    height: 252px !important;
    max-height: 252px !important;
    grid-gap: 0.5em;
    width: calc(100vw - 2em - 36px) !important; /* Layout Calculation */
}

@media (max-width: 1000px) {
    .Details {
        height: auto !important;
        max-height: 60vh !important; /* Layout Calculation */
        max-height: calc(var(--vh, 1vh) * 60) !important; /* Mobile Layout Calculation */
        grid-gap: 2px;
    }
}

@media (max-width: 500px) {
    .Details {
        width: calc(100vw - 1em) !important; /* Layout Calculation */
    }
}

.CardBtn {
    padding-top: 0.5em !important;
}

.CardBtnContent {
    min-width: 80px;
    display: grid !important;
    grid-gap: 4px;
    place-content: center;
}

.CardBtnIcon {
    display: grid;
    place-content: center;
}

.CardBtnName {
    display: grid;
    place-content: center;
    font-size: 2em;
}

.Home {
    display: grid;
    place-content: start center;
    color: lightgray;
    padding: 6em 0 1em 0;
}

.HomeName {
    display: grid;
    font-size: 3em;
    font-weight: 300;
    text-align: center;
}

@media (max-width: 1000px) {
    .CardBtnName {
        font-size: 1em !important;
    }
}

@media (max-width: 500px) {
    .Home {
        padding: 0;
        place-content: center;
    }
}

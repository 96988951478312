.Invalid {
    color: #424242 !important;
    background-color: inherit;
}

.InProgress {
    color: #ef6c00 !important;
    background-color: #ffe0b2 !important;
}

.Done {
    color: #558b2f !important;
    background-color: #dcedc8 !important;
}

.Details {
    display: grid;
    grid-template-rows: 2fr 5fr 2fr 1fr;
    height: 252px !important;
    max-height: 252px !important;
    grid-gap: 4px;
    width: calc(100vw - 2em - 36px) !important; /* Layout Calculation */
    padding: 0 0.5em;
}

.DetailsInput {
    display: grid;
    align-content: end;
}

.DetailsInput > * {
    display: grid;
    place-content: center;
    padding-left: 1em;
    min-width: 240px;
}

.Title {
    display: grid;
    place-content: center start;
    font-size: 1em;
}

.Actions {
    display: grid;
    grid-gap: 0.5em;
    grid-auto-flow: column;
    place-content: end center;
    grid-template-columns: minmax(50px, 1fr) minmax(100px, 4fr) minmax(50px, 1fr);
}

.Actions button {
    min-height: 2em !important;
    min-width: 2em !important;
}

.Meta {
    display: grid;
    place-content: end center;
}

.ActionsNav {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
    touch-action: manipulation;
}

.ActionsNav button {
    width: 100%;
}

.TabHead {
    display: grid;
    padding: 0;
    margin: 0 auto 0 0;
}

.TabHead > * {
    display: grid;
    grid-gap: 1em;
    padding: 0 !important;
    grid-auto-flow: column;
}

@media (max-width: 1000px) {
    .Details {
        height: auto !important;
        max-height: 60vh !important; /* Layout Calculation */
        max-height: calc(var(--vh, 1vh) * 60) !important; /* Mobile Layout Calculation */
        grid-gap: 2px;
    }

    .TabHead {
        min-height: auto;
    }

    .Title {
        font-size: 0.9em;
    }

    .Actions {
        display: grid;
        grid-gap: 0.5em;
        grid-auto-flow: column;
        place-content: end center;
    }

    .Actions button {
        font-size: 0.6em;
    }

    .ActionsNav {
        grid-gap: 1em;
    }

    .Meta > * {
        font-size: 0.6em !important;
    }
}

@media (max-width: 500px) {
    .Details {
        width: calc(100vw - 1em) !important; /* Layout Calculation */
    }
}

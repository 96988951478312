.ReordersAlerts {
    display: grid;
    place-content: start stretch;
    grid-gap: 1em;
    padding: 1em 0 0 1em;
    width: calc(100vw - 64px);
    place-items: start stretch;
    overflow-y: auto;
}

.OpenAlerts,
.SnoozedAlerts,
.CompletedAlerts {
    display: grid;
    place-content: center stretch;
    grid-gap: 0.5em;
}

.OpenAlert,
.SnoozedAlert,
.CompletedAlert {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5em;
}

.OpenAlert span,
.SnoozedAlert span,
.CompletedAlert span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.25em 0;
    grid-auto-flow: column;
}

.OpenAlert > span,
.SnoozedAlert > span,
.CompletedAlert > span {
    display: grid;
    place-content: center start;
}

.OpenAlert {
    grid-template-columns: minmax(80px, 10fr) minmax(40px, 5fr) minmax(32px, 4fr) minmax(48px, 6fr) minmax(
            85px,
            10fr
        );
}

.SnoozedAlert {
    background-color: #ff000010;
    color: #b71c1c;
    grid-template-columns: minmax(80px, 10fr) minmax(40px, 5fr) minmax(32px, 4fr) minmax(88px, 11fr) minmax(
            45px,
            5fr
        );
}

.CompletedAlert {
    grid-template-columns: minmax(80px, 10fr) minmax(40px, 5fr) minmax(32px, 4fr) minmax(88px, 11fr) minmax(
            45px,
            5fr
        );
}

.AlertTypeDividers {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: min-content min-content 1fr min-content;
    place-content: center start;
    grid-gap: 0.5em;
    border-bottom: 2px solid;
}

.AlertTypeDividers > span {
    font-size: 1em !important;
}

.ItemsCount {
    border: 1px solid;
    border-radius: 20%;
    padding: 0 0.5em;
    margin: 0.25em;
}

.ItemName {
    font-weight: 600;
}

.ItemStock::before {
    content: "Stock";
    font-weight: 600;
    margin-right: 0.5em;
}

.ItemPar::before {
    content: "Par";
    font-weight: 600;
    margin-right: 0.5em;
}

.ItemSnoozedUntil::before {
    content: "Snooze";
    font-weight: 600;
    margin-right: 0.5em;
}

.ItemDoneBy::before {
    content: "By";
    font-weight: 600;
    margin-right: 0.5em;
}

.Controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5em;
    place-content: center end;
}

.Controls button {
    margin: 0 !important;
    padding: 0 !important;
}

.Controls button span {
    font-size: 0.8em;
}

.LastUpdatedAlert {
    background-color: transparent !important;
    background: repeating-linear-gradient(135deg, #ccc, #ccc 1px, white 2px, white 10px) !important;
}

@media (max-width: 1000px) {
    .Controls button {
        min-width: 43px;
        font-size: 0.7em;
    }

    .OpenAlerts span,
    .SnoozedAlerts span,
    .CompletedAlerts span {
        font-size: 0.7em;
    }
}

@media (max-width: 500px) {
    .ReordersAlerts {
        width: calc(100vw - 1em);
    }
}

.Reception {
    display: grid;
    place-content: start stretch;
    grid-template-rows: min-content 1fr;
}

.ReceptionToolbar {
    display: grid;
    place-content: center start;
    padding: 0.5em 1em;
}

.ReceptionContent {
    display: grid;
    place-content: start stretch;
    grid-gap: 0.5em;
}

.ReceptionContentTask {
    display: grid;
    margin: 0 1em;
    grid-auto-flow: column;
    grid-gap: 0.5em;
    padding: 0.5em 1em;
    grid-template-columns: 3fr 1fr 5fr 96px;
    border-bottom: 1px solid #ccc;
}

.ReceptionContentTask span {
    text-align: left;
}

.Middle {
    display: grid;
    place-content: center stretch;
    grid-template-rows: minmax(30px, 1fr) minmax(40px, 2fr) minmax(30px, 1fr);
}

.Middle button {
    font-size: 0.8em;
}

.InputWrp {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    width: 100%;
}

.InputWrp > input {
    line-height: 1em;
    text-align: center;
    border: none;
    font-size: 1em;
    font-weight: 600;
    min-width: 80px;
}

.TotalAmountWrp {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5em;
    place-content: center;
    text-transform: none !important;
    border-bottom: 2px solid;
}

.TotalAmountWrp * {
    font-size: 1.5em !important;
    font-weight: 600;
}

.TextEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 1000px) {
    .Middle {
        min-width: 64px;
    }

    .Middle > button {
        font-size: 0.6em;
    }

    .InputWrp input {
        line-height: 1em;
        text-align: center;
        border: none;
        min-width: 40px;
    }

    .TotalAmountWrp {
        font-size: 0.8em !important;
    }

    .TotalAmountWrp * {
        font-size: 1.4em !important;
        font-weight: 600;
    }
}

@media (max-width: 500px) {
    .Middle > button {
        font-size: 0.8em;
    }

    .TotalAmountWrp {
        font-size: 0.8em !important;
    }

    .TotalAmountWrp * {
        font-size: 1.2em !important;
    }
}

.Main {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr minmax(min-content, 100px) minmax(min-content, 80px);
    padding: 0 1em;
}

.Selection {
    display: grid;
    grid-auto-flow: column;
    place-content: center start;
}

.Status {
    display: grid;
    grid-auto-flow: column;
    place-content: center end;
}

.SyncWrp {
    display: grid;
    place-content: center;
}

.RestaurantStatus {
    font-weight: 600;
    font-size: 0.8em;
    text-transform: uppercase;
    background-color: transparent !important;
}

.SelectionRestaurant {
    min-width: 120px !important;
}

.SelectionRestaurant .Mui-select {
    font-size: 1em !important;
}

.Sync,
.Finish,
.Unlock {
    font-weight: 600 !important;
    padding: 2px 24px !important;
}

@media (max-width: 1000px) {
    .Main {
        place-content: center;
    }

    .Selection div {
        font-size: 0.9em !important;
    }

    .Sync,
    .Finish,
    .Unlock {
        font-size: 0.8em !important;
    }

    .SelectionRestaurant {
        min-width: unset !important;
    }
}

.Main {
    display: grid;
}

.Main * {
    touch-action: manipulation;
}

.InputWrp {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
}

.InputWrp > input {
    line-height: 1em;
    text-align: center;
    border: none;
    font-size: 1em;
    font-weight: 600;
    min-width: 80px;
}

.Error {
    color: red;
}

.Input {
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    grid-gap: 0.25em;
    margin: 0;
}

.Input * {
    touch-action: manipulation;
}

.Input > button {
    margin: 0 !important;
    font-size: 1em;
    line-height: 1em !important;
    min-width: 4.5em;
    font-weight: 600;
}

.Middle {
    display: grid;
    place-content: center stretch;
}

.Middle button {
    font-size: 0.8em;
}

.Negatives,
.Positives {
    display: grid;
    grid-gap: 0.5em;
    grid-template-rows: 1fr 1fr;
}

.Negatives > *,
.Positives > * {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
}

.Negatives > * {
    place-content: stretch end;
}

.Positives > * {
    place-content: stretch start;
}

.Delivery {
    font-size: 0.8em;
    font-weight: 300;
    color: #4caf50;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 3px;
    place-content: center;
}

@media (max-width: 1000px) {
    .Input {
        display: grid;
        grid-template-columns: minmax(80px, 2fr) minmax(40px, 1fr) minmax(80px, 2fr);
        margin: 0;
    }

    .Input > button {
        margin: 0 !important;
        line-height: 1em !important;
    }

    .Input > button {
        font-size: 0.7em !important;
    }

    .InputWrp input {
        line-height: 1em;
        text-align: center;
        border: none;
        min-width: 40px;
    }

    .Middle {
        min-width: 64px;
    }

    .Middle > button {
        font-size: 0.6em;
    }

    .Negatives,
    .Positives {
        display: grid;
        grid-gap: 0.25em;
        grid-template-rows: 1fr 1fr;
    }
}

@media (max-width: 500px) {
    .Input > button {
        min-width: 2em;
        padding: 0;
        font-size: 0.8em !important;
    }

    .Middle > button {
        font-size: 0.8em;
    }

    .Negatives > *,
    .Positives > * {
        font-size: 0.8em;
    }
}

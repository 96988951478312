.Main {
    display: grid;
    grid-template-rows: 40px 30px 1fr;
    padding: 0 1em;
    margin-bottom: 1em;
}

.Name {
    display: grid;
    place-content: center start;
    font-weight: 600;
    font-size: 1.2em;
}

.Header {
    font-weight: 600;
    border: 1px solid;
    font-size: 1.2em;
    background-color: #dcedc8 !important;
}

.Content {
    display: grid;
    grid-gap: 2px;
    border: 1px solid;
}

.Item {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: minmax(70px, 3fr) minmax(30px, 1fr) minmax(40px, 2fr) minmax(30px, 2fr);
    grid-gap: 0.5em;
    padding: 0 4px;
    text-align: left;
    background-color: white;
}

.Item > * {
    padding-top: 2px;
    padding-bottom: 2px;
}

@media (max-width: 1000px) {
    .Main {
        grid-template-rows: 30px 22px 1fr;
        margin-bottom: 0.5em;
    }

    .Name {
        font-size: 1em;
    }

    .Header {
        font-size: 0.8em;
    }

    .Item {
        font-size: 0.8em;
    }
}

.Title {
    display: grid;
    place-content: center end;
    font-size: larger;
    padding-right: 1em;
    color: white;
}

.TasksWrp {
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    place-content: stretch space-between;
    place-items: stretch;
    grid-auto-flow: column;
    grid-gap: 0.5em;
}

.TasksWrp > * {
    color: white;
}

.TasksCounter {
    display: grid;
    place-content: center;
}

.TasksStatusCounter {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.TasksCounterButton {
    font-weight: 600 !important;
    color: white;
    text-transform: lowercase;
    min-width: 9em !important;
}

.TasksCounterButton span {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    min-width: 8em !important;
}

.TasksCounterButton .MuiButton-startIcon,
.TasksCounterButton .MuiButton-endIcon {
    margin: 0 !important;
}

.TasksNavigatorWrp {
    display: grid;
    place-content: center stretch;
    grid-auto-flow: column;
    grid-gap: 0.5em;
}

.TasksNavigatorStart {
    display: grid;
}

.TasksNavigatorStartButton {
    background-color: #8bc34a !important;
    color: white !important;
    font-size: 0.8em !important;
    font-weight: 600;
}

.TasksNavigatorStartButton * {
    display: grid;
    place-content: center start;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TasksNavigator {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5em;
    grid-template-columns: min-content 1fr min-content;
}

.TasksNavigatorLeft,
.TasksNavigatorRight {
    background-color: #eee !important;
    padding: 0 !important;
    font-size: 1em;
    touch-action: manipulation;
}

.TaskNavigatorItem {
    display: grid;
    place-content: center start;
    min-width: 40px;
    /* TODO: make ellipsis class */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9em;
}

.ProgressCounter {
    display: grid;
    text-transform: lowercase;
    grid-auto-flow: column;
    margin: 0 0.25em;
    /* TODO: make ellipsis class */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9em;
}

.TextEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.OffSiteStatusTableWrp td,
.OffSiteStatusTableWrp th {
    border: 1px solid #eee;
    text-align: left;
}

.TasksFilter {
    display: grid;
    grid-gap: 1em;
    grid-auto-flow: column;
    font-size: 0.8em;
}

.RestaurantTasks {
    margin-bottom: 1em;
}

.RestaurantTasksLabel {
    font-size: larger;
    height: 2em;
    display: grid;
    place-content: center start;
    background-color: #ddd;
    margin: 0 -1.5em;
    padding: 0 1.5em;
}

.TasksDialog {
    /* background-color: red; */
}

.TasksDialogContent {
    /* background-color: yellow; */
    overflow-x: hidden;
}

.TasksDialogActions {
    /* background-color: blue; */
}

.RestaurantTasksRow {
    display: grid;
    place-content: center start;
    grid-auto-flow: column;
    grid-template-columns: 8fr 2fr 3fr 5fr;
    width: 100%;
    border-bottom: 1px solid #eee;
}

.RestaurantTasksTransit span {
    background-color: #00800020;
    color: #008000;
}

.RestaurantTasksLoaded span {
    background-color: #ffa50010;
    color: #ffa500;
}

.RestaurantTasksPending span {
    color: #ff0000;
}

.RestaurantTasksHeader {
    font-weight: 600;
}

.RestaurantTasksRow * {
    font-size: 1em;
    padding: 0.25em;
}

.OffSiteDialogActions {
    display: grid;
    grid-gap: 1em;
    grid-auto-flow: column;
}

@media (max-width: 1000px) {
    .RestaurantTasksRow * {
        font-size: 0.8em;
    }
}

@media (max-width: 500px) {
    .ProgressCounter {
        font-size: 0.8em;
    }

    .TaskNavigatorItem {
        font-size: 0.8em;
    }

    .TasksNavigatorLeft,
    .TasksNavigatorRight {
        font-size: 0.8em;
    }

    .TasksNavigatorStartButton {
        font-size: 0.7em !important;
    }
}

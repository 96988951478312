.Input {
    display: grid;
    grid-template-columns: 2fr minmax(84px, 1fr) 2fr;
    grid-gap: 0.5em;
    margin: 0;
}

.Input * {
    touch-action: manipulation;
}

.Input > button {
    margin: 0 !important;
    font-size: 1em;
    line-height: 1em !important;
    min-width: 4.5em;
    font-weight: 600;
}

@media (max-width: 1000px) {
    .Input {
        display: grid;
        margin: 0;
    }

    .Input > button {
        margin: 0 !important;
        line-height: 1em !important;
    }

    .Input > button {
        font-size: 0.7em !important;
    }
}

@media (max-width: 500px) {
    .Input > button {
        min-width: 2em;
        padding: 0;
        font-size: 0.8em !important;
    }
}

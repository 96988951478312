.IconDelivery {
    display: grid;
    grid-template-rows: 1fr min-content;
    place-items: center;
}

.IconDelivery > span {
    font-size: 7px;
    text-transform: uppercase;
}

.global-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 30px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.Main {
    display: grid;
    grid-template-rows: 3em min-content 1fr;
    border: 2px solid #8bc34a;
}

.Header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 40px 1fr 40px;
    padding: 0.5em 0.5em 0.5em 0;
    place-content: center;
}

.Content {
    display: grid;
}

.Close {
    padding: 0 !important;
}

@media (max-width: 1000px) {
    .Main {
        grid-template-rows: 1em min-content 1fr;
    }

    .Header {
        grid-template-columns: 20px 1fr 20px;
        padding: 0.25em 0.25em 0.25em 0;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
    margin: 0;
    background-color: #f5f5f5;
}

html,
body,
#root {
    height: 100%;
}

label {
    color: #999;
    font-style: italic;
}

.error-default {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    place-content: center;
    font-size: 2em;
    padding: 0.5em;
    color: white;
    background-color: #f44336;
}

.app-bar {
    background-color: white !important;
}

.section-header {
    border-bottom: 1px solid;
    text-transform: uppercase;
    margin-top: 0;
}

/* Page */
#root {
    display: grid;
    grid-template-rows: min-content auto;
}

/* Header */
#root > header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

/* Search */
#search {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content 1fr;
}

#search input {
    font-size: 1em;
    padding: 0.25em 1em;
    width: 80%;
}

#predictions > div {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: minmax(6em, 1fr) minmax(6em, 1fr);
}

#predictions input {
    font-size: 1em;
    padding: 0.25em 1em;
    margin-bottom: 12px;
    text-align: left;
    width: 4em;
}

/* Inventory */
#results {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content 1fr;
}

#results .content {
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: repeat(auto-fill, 5em);
}

#results .btn-wrp {
    display: grid;
}

#results button {
    font-size: 1em;
    background-color: lightgreen;
    border-radius: 0.5em;
}

#results button:disabled {
    background-color: #ccc;
}

/* Table */
.row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}

/* Table > cell */
.row > * {
    display: grid;
    place-content: center;
    height: 2em;
    max-height: 2em;
    padding: 2px;
}

.header {
    color: orangered;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
}

/* Table > header */
.row:first-child > :first-child {
    border-right: 1px solid #f5f5f5;
}

.row:first-child {
    font-weight: 600;
    border-bottom: 2px solid;
}

/* Table > header > cells */
.row:first-child > * {
    border-bottom: 2px solid;
    margin: 0;
    display: grid;
    place-content: center;
    overflow: hidden;
    height: 50px;
    max-height: 50px;
}

/* Table > horizontal pattern */
.row:nth-child(2n) {
    background-color: #e9e9e9;
}

.row:nth-child(2n) {
    background-color: white;
}

/* Table > first cell */
.row > :first-child {
    border-right: 1px solid;
    min-width: 150px;
}

.location-menu-wrp > section {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content auto;
}

.location-menu-wrp > section > div {
    display: grid;
}

.location-menu-item-row {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 200px 100px 100px 1fr;
}

.location-menu-item-row:first-child > :nth-child(2) {
    background-color: #4caf50;
}

.location-menu-item-row:first-child > :nth-child(3) {
    background-color: #81c784;
}

.location-menu-item-row:nth-child(2n) > :nth-child(2) {
    background-color: #a5d6a7;
}

.location-menu-item-row > :nth-child(2) {
    border-right: 1px solid;
}

.location-menu-item-row:nth-child(2n) > :nth-child(3) {
    background-color: #c8e6c9;
}

.location-menu-item-row > :nth-child(3) {
    border-right: 1px solid;
}

.location-menu-item-row > .recipe {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-content: center start;
    padding-left: 0.5em;
}

/* LocationMenus rows */
[id^="location-menu-"] .row {
    grid-template-columns: 200px 100px 100px minmax(400px, 1fr);
    grid-auto-columns: unset;
}

/* Recipe */
.recipe {
    display: grid;
    grid-auto-flow: column;
    place-content: center start;
}

.recipe > div {
    display: grid;
    grid-gap: 0.5em;
    grid-auto-flow: column;
    place-content: center start;
    text-align: left;
    padding-right: 0.5em;
    grid-auto-columns: max-content;
}

/* Recipe: Item */
.recipe > div {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.25em;
}

/* Recipe: Quantity */
.recipe > div > :nth-child(1) {
    font-weight: 600;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
}

/* Recipe: Ingredient */
.recipe > div > :nth-child(2) {
    padding-left: 2px;
    padding-right: 2px;
    text-align: left;
    width: max-content;
}

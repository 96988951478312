.Main {
    display: grid;
    align-content: start;
    background-color: white;
}

.Main > * {
    text-decoration: none !important;
}

.Item {
    cursor: pointer;
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-rows: 1fr min-content;
    padding: 1em 0 0.5em 0;
    color: #606060;
}

.Item:hover {
    background-color: #eeeeee;
}

.Item > div {
    display: grid;
    place-content: center;
}

.Item > div > span {
    font-size: 0.7em;
}

@media (max-width: 1000px) {
    .Item > div > span {
        font-size: 0.5rem;
    }
}

.Main {
    padding-right: 2px;
    width: 100%; /* Layout Calculation */
    max-width: 100%; /* Layout Calculation */
    overflow-y: scroll;
}

.Main > * {
    margin-bottom: 0.75em;
    touch-action: manipulation;
}

.Main .MuiExpansionPanelDetails-root {
    padding: 0;
    border: 1px solid #dddddd;
}

.Main .MuiExpansionPanelSummary-content {
    margin: 0 !important;
    padding: 0.5em 0;
    min-height: 2.5em !important;
}

.ContentHeader {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    grid-gap: 0.25em;
}

@media (max-width: 1000px) {
    .Main > * {
        margin-bottom: 0.5em;
    }
}

@media (max-width: 500px) {
    .Main {
        padding-bottom: calc(100vh - 90px - 90px); /* Layout Calculation */
        padding-bottom: calc(var(--vh, 1vh) * 100 - 90px - 90px); /* Mobile Layout Calculation */
    }
}

.menus {
    display: grid;
    grid-template-rows: 64px 112px min-content;
    height: 100%;
}

.menusCompletion .MuiLinearProgress-root {
    height: 0.5em;
}

.menusContent {
    height: calc(100vh - 240px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 240px); /* Mobile Layout Calculation */
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    width: 100%;
    background-color: transparent !important;
    padding: 0 2em;
}

.menuContentItems {
    display: grid;
    overflow: auto;
    border: 1px solid #ddd;
}

.menuContentHeader {
    font-size: 1.2em;
    font-weight: 600;
    color: white;
    background-color: #3f51b5 !important;
}

.menuContentHeader button {
    color: white;
}

.menuContentHeader > * {
    color: white;
    border-color: #3f51b5 !important;
}

.menuContentItem {
    display: grid;
    grid-auto-flow: column;
    place-content: start center;
    grid-template-areas: "menuItem salesSingle salesMultiple recipe";
    grid-template-columns: min-content min-content min-content 1fr;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.menuItem {
    grid-area: menuItem;
    min-width: 200px;
    width: 200px;
    max-width: 200px;
}

.menuItemSaleSingle {
    grid-area: salesSingle;
    min-width: 80px;
    width: 80px;
    max-width: 80px;
}

.menuItemSaleMultiple {
    grid-area: salesMultiple;
    min-width: 80px;
    width: 80px;
    max-width: 80px;
}

.menuItemRecipe {
    grid-area: recipe;
}

.menuItemMissingRecipe {
    background: repeating-linear-gradient(
        135deg,
        #f5005790,
        #f5005790 2px,
        #f5005740 2px,
        #f5005740 10px
    ) !important;
}

.menuContentItem > * {
    line-height: 30px;
    display: inline-block;
    border-right: 1px solid #ddd;
    padding: 0.5em;
    text-align: center;
}

.menuContentItem > :last-child {
    text-align: left;
    border-right: none;
}

.menuContentItem:nth-child(2n) {
    background-color: #f5f5f5;
}

.menuContentItem:nth-child(2n + 1) {
    background-color: white;
}

@media print {
    .menusContent {
        height: calc(100vh - 224px + 48px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 224px + 48px); /* Mobile Layout Calculation */
    }
}

@media (max-width: 1000px) {
    .menus {
        grid-template-rows: 48px min-content;
    }

    .menusContent {
        height: calc(100vh - 100px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 100px); /* Mobile Layout Calculation */
    }

    .menusContent * {
        font-size: 0.8em;
    }

    .menuItem {
        min-width: 80px;
        width: 80px;
    }

    .menuItemSaleSingle,
    .menuItemSaleMultiple {
        min-width: 40px;
    }

    .menuItemRecipe {
        min-width: 120px;
    }

    .menuContentItem > * {
        line-height: 10px;
    }
}

@media (max-width: 500px) {
    .menusContent {
        height: calc(100vh - 90px - 50px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 90px - 50px); /* Mobile Layout Calculation */
        padding: 0 1em 1em 1em;
    }
}

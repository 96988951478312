.Main {
    height: calc(100vh - 36px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 36px); /* Mobile Layout Calculation */
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    padding: 0.5em 0 0 0.5em;
    grid-auto-flow: row;
    grid-template-rows: min-content 1fr;
}

@media print {
    .Main {
        height: 100vh; /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100); /* Mobile Layout Calculation */
    }
}

@media (max-width: 500px) {
    .Main {
        padding: 0.5em;
        height: calc(100vh - 90px); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 90px); /* Mobile Layout Calculation */
    }
}

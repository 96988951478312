.Main {
    display: grid;
    height: 100vh; /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100); /* Mobile Layout Calculation */
    width: 100vw; /* Layout Calculation */
    min-width: 100vw; /* Layout Calculation */
    max-width: 100vw; /* Layout Calculation */
    grid-template-rows: 36px 1fr;
    grid-template-columns: 48px 1fr;
    grid-template-areas:
        "header header"
        "menu main";
    overflow-x: hidden;
}

.Main > header {
    grid-area: header;
    width: 100vw; /* Layout Calculation */
    max-width: 100vw; /* Layout Calculation */
}

.TestEnv > header {
    background: repeating-linear-gradient(
        135deg,
        #3f50b690,
        #3f50b690 1px,
        #3f50b6 1px,
        #3f50b6 20px
    ) !important;
}

.Main > main {
    grid-area: main;
}

.Main > aside {
    grid-area: menu;
    overflow: auto;
    height: calc(100vh - 36px - 2em); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 36px - 2em); /* Mobile Layout Calculation */
}

@media (max-width: 1000px) {
    .Main {
        grid-template-columns: 36px 1fr;
    }
}

@media print {
    .Main {
        height: auto;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: "main";
    }
}

@media (max-width: 500px) {
    .Main {
        grid-template-rows: 36px 1fr 56px;
        grid-template-columns: unset;
        grid-template-areas: "header" "main" "menu";
    }

    .Main > header {
        grid-area: header;
        width: 100vw; /* Layout Calculation */
        max-width: 100vw; /* Layout Calculation */
    }

    .Main > main {
        grid-area: main;
        width: 100vw !important;
        height: calc(100vh - 36px - 64px - 1em); /* Layout Calculation */
        height: calc(var(--vh, 1vh) * 100 - 36px - 64px - 1em); /* Mobile Layout Calculation */
    }

    .Main > aside {
        grid-area: menu;
        height: 56px;
        width: 100vw !important; /* Layout Calculation */
        grid-auto-flow: column;
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }
}

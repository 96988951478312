.Main {
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    width: 160px;
    min-width: 80px !important;
}

.Main > span {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 64px 1fr;
}

.Main svg {
    min-width: 64px;
}

.Main div {
    font-weight: 600;
    font-size: 1.2em;
    text-align: left;
    color: white;
}

.Icon {
    display: grid;
    place-items: center;
}

.Label {
    width: 75px;
    font-size: 1em !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1000px) {
    .Main {
        width: 100%;
        max-width: 160px;
    }

    .Main div {
        font-size: 1em;
    }

    .Main > span {
        grid-template-columns: 1fr 2fr;
        place-content: center;
    }

    .Main svg {
        min-width: 32px;
    }
}

@media (max-width: 500px) {
    .Icon svg {
        min-width: 20px !important;
    }

    .Label {
        width: 45px;
        font-size: 0.7em !important;
    }
}

.ItemAlertWrp {
    display: grid;
    place-content: start stretch;
    grid-auto-flow: column;
    grid-template-columns: 2fr 3fr;
    grid-gap: 0.5em;
    height: 150px;
    overflow: hidden;
}

.ItemAlertEditor {
    display: grid;
    place-content: start stretch;
    grid-template-rows: 1fr min-content;
    border-right: 1px solid #ddd;
}

.ItemAlertEditorMessage {
    display: grid;
    place-content: stretch;
    padding: 0 0.5em 0.5em 0.5em;
}

.ItemAlertEditorMessage * {
    font-size: 0.9em !important;
}

.ItemAlertEditorSubmit {
    display: grid;
    place-content: center;
}

.ItemAlertHistory {
    display: grid;
    place-content: start stretch;
    overflow: hidden;
}

.ItemAlertHistoryHeader {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 3fr 2fr;
    border-bottom: 1px solid #ddd;
}

.ItemAlertHistoryHeader span {
    font-size: 0.7em;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.ItemAlertHistoryItem {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.ItemAlertHistoryItemDetails {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 3fr 2fr;
}

.ItemAlertHistoryItemDetails span {
    font-size: 0.6em;
}

.ItemAlertHistoryItemMessage {
    display: grid;
    place-content: start;
}

.Main {
    position: relative;
    display: grid;
    place-content: center;
    font-size: 0.9em;
}

.Decrease {
    position: absolute;
    left: 0;
    height: 100%;
    width: 50%;
    /* background-image: linear-gradient(to right, white 10%, transparent 90%); */
    display: grid;
    place-content: center start;
    padding-left: 2px;
}

.Increase {
    position: absolute;
    right: 0;
    height: 100%;
    width: 50%;
    /* background-image: linear-gradient(to left, white 10%, transparent 90%); */
    display: grid;
    place-content: center end;
    padding-right: 2px;
}

.Main {
    overflow: hidden;
    display: grid;
    grid-template-rows: 60px 80px 1fr 32px;
    background-color: white;
}

.Main > * {
    width: calc(100vw - 100px);
    margin: 0 auto;
}

.Main > div:last-child {
    background-color: white;
}

.Main > :last-child {
    border-top: 1px solid #ccc;
}

/* TOOLBAR */
.Toolbar {
    display: grid;
    grid-auto-flow: column;
    place-content: center space-between;
    width: calc(100vw - 100px); /* Layout Calculation */
    background-color: white;
}

.ToolbarInfo {
    display: grid;
    place-content: center center;
}
/* ROW */
.Row {
    display: grid;
    grid-auto-flow: column;
    width: calc(100vw - 117px); /* Layout Calculation */
    grid-template-columns: 5fr 3fr 8fr 8fr 6fr;
}

.Row > * {
    display: grid;
    place-content: stretch;
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

.Row > :nth-child(1),
.Row > :nth-child(2) {
    place-content: center;
}

.Row > *:last-child {
    border-right: 1px solid #cccccc;
}

.Row:last-child {
    border-bottom: 1px solid #cccccc;
}

.Row:last-child > * {
    border-bottom: none;
}

/* HEADER */
.Header {
    display: grid;
    border-bottom: 1px solid #ccc;
    background-color: white;
}

/* Cover the scroll bar */
.Header .Row > :last-child {
    margin-right: -15px;
}

.Header .Row > :last-child > :last-child {
    margin-right: 15px;
}

.Header .Row,
.Header .Row > * {
    border: none;
}

.Header .Row > :nth-child(1),
.Header .Row > :nth-child(2) {
    font-size: 1.2em;
    text-transform: uppercase;
}

.ItemRestaurantHeader {
    display: grid;
    grid-template-rows: 40px 40px;
}

.CellRestaurantName {
    display: grid;
    font-weight: 600;
    place-content: center;
}

.CellsRestaurantSections {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
}

.CellsRestaurantSections > * {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
}

/* CONTENT */
.Content {
    display: grid;
    overflow-y: scroll;
    height: calc(100vh - 220px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 220px); /* Mobile Layout Calculation */
    place-content: start center;
}

.Content > * {
    min-height: 40px;
}

/* SECTIONS */
.Sections {
    height: calc(100vh - 220px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 220px); /* Mobile Layout Calculation */
    overflow-y: scroll;
    background-color: white;
}

.Section {
    background-color: white;
    break-inside: avoid-page;
    padding-bottom: 2em;
}

.SectionHeader {
    display: grid;
    place-content: start center;
    font-size: 1.2em;
    font-weight: 600;
    width: 100%;
    padding: 6px;
    background-color: white;
    border-bottom: 2px solid;
}

.SectionRow {
    display: grid;
    place-content: center;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 3fr 1fr 1fr 1fr;
    background-color: white;
}

.Section > .SectionRow:nth-child(2) {
    grid-template-columns: 2fr 1fr 3fr 2fr 1fr;
}

.Section > .SectionRow:nth-child(2) > * {
    padding: 4px;
    font-size: 0.8em;
    text-transform: uppercase;
    background-color: #ddd;
    font-weight: 600;
    border-bottom: 2px solid;
}

.Section > :last-child {
    border-bottom: 1px solid;
}

.SectionRow > * {
    display: grid;
    place-content: center;
    padding: 0.5em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    min-width: 60px;
}

.SectionRow > .SectionRowQuantity {
    display: grid;
    place-content: center end;
    border-right: none !important;
    padding-right: 2px;
    font-weight: 600;
}

.SectionRow > .SectionRowPackType {
    display: grid;
    place-content: center start;
    padding-left: 2px;
}

.SectionRow > :first-child {
    border-left: 2px solid;
}

.SectionRow > :last-child {
    border-right: 2px solid;
}

.Section > :last-child {
    border-bottom: 1px solid;
}

.Signature {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 4fr 3fr;
    margin-bottom: 2em;
}

.Signature > * {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}

/* CELL */
.Cell {
    text-align: center;
    cursor: help;
    display: grid;
    place-content: center;
}

.ON_HAND {
    background-color: rgba(255, 224, 160, 0.4);
}

.SINGLE,
.MULTIPLE {
    background-color: rgba(255, 224, 224, 0.4);
}

.DELIVERY,
/* .DELIVERY_FIXED, */
.DELIVERY_SALES,
.PREP,
.PREP_FOR_DELIVERY {
    background-color: rgba(197, 225, 165, 0.4);
}

.Cell:hover {
    background-color: #e8eaf6;
}

/* ITEM */
.Item {
}

/* ITEM RESTAURANT */
.ItemRestaurant {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}

.ItemRestaurant > * {
    display: grid;
}

.RestaurantDeliveryHeader {
    display: grid;
    grid-template-rows: 40px 30px;
    place-content: center;
}

.RestaurantDeliveryHeader > * {
    display: grid;
    place-content: center;
}

.RestaurantDeliveryHeader > :first-child {
    place-content: end center;
    font-size: 2em;
    text-transform: none;
    font-weight: 600;
}

@media (max-width: 1000px) {
    .Row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 2fr 1fr 4fr 4fr 3fr;
    }

    .Content * {
        font-size: 0.8em;
        text-transform: uppercase;
    }
}

@media print {
    .Main {
        height: auto;
        width: auto;
        overflow: auto;
        grid-template-rows: 80px 1fr;
        border-left: none;
    }

    .Content {
        overflow: auto;
        height: auto !important;
    }

    .Content * {
        font-size: 1em;
    }

    .Content > * {
        min-height: 24px;
    }

    .Sections {
        background-color: white;
        height: auto;
        overflow: visible;
    }

    .DELIVERY,
    .DELIVERY_SALES,
    .DELIVERY_FIXED,
    .ON_HAND,
    .SINGLE,
    .MULTIPLE,
    .PREP_FOR_DELIVERY,
    .PREP {
        background-color: inherit;
        font-size: 1.2em !important;
    }

    .Header > div > div:nth-child(1),
    .Header > div > div:nth-child(2) {
        font-weight: 600;
        font-size: 1em;
        text-transform: uppercase;
    }

    .Row {
        break-inside: avoid-page;
        grid-template-columns: repeat(auto-fit, minmax(100px, 2fr));
    }

    .Row > div:nth-child(1),
    .Row > div:nth-child(2) {
        text-transform: uppercase;
        font-size: 1em;
        padding: 4px;
        display: grid;
        place-content: center;
    }

    /* UN-Covers the scroll bar */
    .Header .Row > :last-child {
        margin-right: 0;
    }

    .Header .Row > :last-child > :last-child {
        margin-right: 0;
    }
}

@media (max-width: 500px) {
    .Main {
        grid-template-rows: 40px 60px 1fr 16px;
    }

    .Main > * {
        width: 100vw; /* Layout Calculation */
    }

    .Toolbar {
        width: 100vw; /* Layout Calculation */
        padding: 0 0.5em;
    }

    .Toolbar * {
        font-size: 0.8em;
    }

    .ItemRestaurantHeader {
        grid-template-rows: 1fr 1fr;
    }

    .Row {
        width: 100vw; /* Layout Calculation */
    }

    .SectionRow {
        padding: 0 0.5em;
    }

    .SectionRow > * {
        min-width: 20px !important;
        font-size: 0.8em;
    }

    .Content * {
        font-size: 0.7em;
    }

    .Header > div > div:nth-child(1),
    .Header > div > div:nth-child(2) {
        font-size: 0.6em !important;
    }

    .CellRestaurantName {
        font-size: 0.8em;
    }

    .CellsRestaurantSections svg {
        height: 10px !important;
        width: 10px !important;
    }
}

.RestaurantSelector {
    display: grid;
}

.Group {
    display: grid !important;
    grid-gap: 0.5em;
}

.Button {
    margin: 0 !important;
    padding: 0.25em 0.5em;
}

.ButtonContent {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.25em;
    grid-template-columns: 1fr min-content;
}

.TextEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Button * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.SelectedPending {
    background-color: #f5005740;
    /* background: repeating-linear-gradient(45deg, transparent, transparent 10px, #8bc34a50 10px, #8bc34a50 20px); */
    border: 2px solid #f50057;
    font-weight: 600;
}

.SelectedPending * {
    color: #f50057;
}

.NotSelectedPending {
    border: 2px solid #f50057;
}

.NotSelectedPending * {
    color: #f50057;
}

.Disabled {
    display: grid;
    border: 1px solid #bbb;
}

.Disabled * {
    color: #999;
}

@media (max-width: 1000px) {
    .Button {
        font-size: 0.8em !important;
        padding-left: 0.5em !important;
        padding-right: 0.5em !important;
    }
}

@media (max-width: 500px) {
    .Button {
        font-size: 0.6em !important;
    }
}

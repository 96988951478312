.Header {
    display: grid !important;
    grid-auto-flow: column;
    grid-template-columns: 1fr 3em;
    border: 1px solid inherit;
    border-left: 1px solid #ddd !important;
    line-height: 1em;
    min-height: 36px !important;
    max-height: 2em;
    padding: 0 0 0 24px !important;
    border-bottom: 1px solid #cccccc !important;
}

.Header * {
    font-size: 1em !important;
}

.Header > div {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 1fr;
    place-content: space-between;
    margin: 0 !important;
}

.Header > :last-child {
    padding: 0 12px;
    display: grid;
    place-content: stretch;
}

.primaryHeader {
    text-align: left;
}

.secondaryHeader {
    text-align: right;
}

.Tabs {
    display: grid;
    place-content: start stretch;
    min-height: 30px !important;
    width: calc(100vw - 2em - 36px); /* Layout Calculation */
}

.Tabs button {
    min-height: 26px !important;
    padding: 0;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}

.Tabs .Mui-selected {
    border: 2px solid;
}

.Tabs .MuiTabs-scrollable > span {
    display: none !important;
}

.Tabs .MuiTabs-scrollButtons {
    background-color: #ddd !important;
}

.Expansion {
    flex-grow: 1;
    background-color: white;
    display: grid !important;
    grid-template-rows: 30px 1fr;
    padding: 0 !important;
    border-left: 1px solid #ddd;
    /*min-height: 252px;*/
    max-height: 292px;
}

.ConfirmTitle {
    padding: 0.5em 1em !important;
    color: white;
    background-color: #3f51b5;
}

.Confirm {
    display: grid;
    grid-gap: 4px;
}

.Confirm > * {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    grid-template-columns: 2fr 1fr 2fr;
    padding-bottom: 0.5em;
}

@media (max-width: 1000px) {
    .Header {
        min-height: 2em !important;
    }

    .Tabs button {
        font-size: 0.6em !important;
    }

    .Expansion {
        /*min-height: 172px;*/
        max-height: 222px;
    }
}

@media (max-width: 500px) {
    .Tabs {
        width: calc(100vw - 2em); /* Layout Calculation */
    }
}

.Details {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 175px;
    grid-gap: 1em;
    min-width: 400px;
    width: calc(100vw - 328px) !important; /* Layout Calculation */
}

.Details * {
    touch-action: manipulation;
}

.Details > div {
    display: grid;
    align-content: start;
}

.Details > div:first-child {
    grid-template-rows: 30px 80px 30px;
    grid-gap: 1em;
    padding: 0 1em 0 2em;
}

.DetailsInput {
    display: grid;
    align-content: center;
    padding: 0 5%;
}

.DetailsInput > * {
    display: grid;
    place-content: center;
    padding-left: 1em;
    min-width: 240px;
}

.Title {
    display: grid;
    place-content: end start;
    font-size: 1em;
}

.Actions {
    display: grid;
    grid-gap: 1em;
    margin-top: 1em;
}

.Actions button {
    width: 175px;
}

.Meta {
}

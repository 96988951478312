.Main {
    display: grid;
    grid-auto-flow: column;
    place-content: start;
    grid-gap: 0.5em;
    color: red;
    text-transform: uppercase;
}

.Main > span {
    font-size: 0.8em;
}

@media (max-width: 1000px) {
    .Main > span {
        font-size: 0.6em !important;
    }
}

@media (max-width: 500px) {
    .Main > span {
        font-size: 0.5em !important;
        padding: 2px 0;
    }
}

.Main {
    display: grid;
    place-items: stretch;
    margin: 0.25em 0.5em !important;
}

@media (max-width: 1000px) {
    .Main {
        margin: 0 !important;
    }

    .Main button {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 500px) {
    .Main {
        /* margin: 0.25em 0.5em !important; */
    }
}

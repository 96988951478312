.Items {
    display: grid;
    grid-template-rows: 40px 74px 40px 10px 1fr;
    height: calc(100vh - 36px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 36px); /* Mobile Layout Calculation */
    padding: 1em 2em;
}

.Toolbar {
    display: grid;
    grid-auto-flow: column;
    place-items: start;
}

.ToolbarSelection,
.ToolbarSelectionForm {
    display: grid;
}

.Content {
    display: grid;
    overflow-y: scroll;
    height: calc(100vh - 250px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 250px); /* Mobile Layout Calculation */
    background-color: #dddddd;
    padding: 1px;
}

.Header {
    padding-right: 15px;
    font-size: 1.2em;
    font-weight: 600;
    color: white;
    background-color: #3f51b5 !important;
}

.Row {
    display: grid;
    grid-gap: 0.5em;
    grid-auto-flow: column;
    grid-template-columns: 90px repeat(auto-fit, minmax(100px, 1fr));
    place-content: center;
    place-items: center;
    text-align: left;
    margin-bottom: 1px;
    background-color: white;
    padding: 4px 0;
}

.Toolbar {
    height: 100%;
}

.Toolbar > div {
    height: 10px !important;
}

.done {
    background-color: white;
}

.incomplete {
    background-color: #f44336;
}

.unknown {
    background-color: #eee;
}

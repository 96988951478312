.Main {
    display: grid;
    text-align: center;
}

.Main input {
    min-width: 40px !important;
}

@media (max-width: 1000px) {
    .Main * {
        font-size: 0.9em;
    }
}

@media (max-width: 500px) {
    .Main * {
        font-size: 0.7em;
    }
}

.Main {
    display: grid;
    place-content: center end;
    place-items: center end;
    grid-auto-flow: column;
    padding-right: 1em;
}

.MenuItem {
    font-size: 0.5em !important;
    text-transform: uppercase !important;
    color: #3f50b6;
    padding: 0.25em 0 !important;
    min-width: 50px !important;
}

.SelectedMenuItem {
    text-decoration: underline !important;
    font-weight: 700 !important;
}

.QuickInputs {
    display: grid;
    grid-gap: 0.5em;
    grid-template-rows: 1fr 1fr 1fr;
}

.QuickInputs > * {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
}

.QuickInputs > * {
    place-content: stretch start;
}

@media (max-width: 1000px) {
    .QuickInputs {
        grid-gap: 0.25em;
    }
}

@media (max-width: 500px) {
    .QuickInputs > * {
        font-size: 0.8em;
    }
}

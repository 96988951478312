.Main {
    display: grid;
    place-content: start stretch;
    min-height: 200px;
    overflow-x: hidden;
    margin: 0;
    padding: 0 0 0 1em;
}

.Item {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 3fr minmax(2em, 1fr) minmax(2em, 1fr) 4fr 2fr 5fr;
    grid-gap: 0.5em;
    border-bottom: 1px solid #ccc;
}

.Value {
    text-align: left;
    font-size: 0.8em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 1000px) {
    .Value {
        font-size: 0.7em;
    }
}

@media (max-width: 500px) {
    .Value {
        font-size: 0.6em;
    }
}

.Main {
    display: grid;
    grid-template-rows: 50px 50px 1fr;
    grid-gap: 1em;
}

.TableHeader {
    display: grid;
    grid-auto-flow: column;
    margin: 0 3em 0 2em;
    font-size: 1.2em !important;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 2px solid !important;
}

.TableHeader > .Explained {
    color: black !important;
}

.Header {
    display: grid;
    grid-auto-flow: column;
    place-content: space-between;
    padding: 0.5em 2em;
}

.Header > div {
    display: grid;
    place-content: center start;
    grid-auto-flow: column;
    grid-gap: 1em;
}

.Content {
    display: grid;
    place-content: start stretch;
    padding: 0 2em;
    height: calc(100vh - 200px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 200px); /* Mobile Layout Calculation */
    overflow: auto;
}

.Content > .Row:hover,
.VendorRow:hover {
    background-color: rgba(255, 165, 0, 0.15);
}

.Row {
    display: grid;
    place-content: center start;
    grid-auto-flow: column;
    grid-template-columns: 3fr 2fr 3fr 2fr 2fr;
    min-height: 32px;
    font-weight: 600;
    border-bottom: 1px solid #cccccc;
}

.VendorRow {
    grid-template-columns: 200px 200px !important;
}

.VendorRow span {
    font-weight: 400 !important;
    break-inside: avoid-page;
}

.VendorContent {
    break-inside: avoid-page;
}

.VendorName {
    text-decoration: underline;
}

.VendorName:hover {
    cursor: pointer;
    background-color: lightblue;
}

.Error {
    color: #b71c1c;
    background-color: #ffcdd2;
    font-weight: 400;
    font-size: 1em !important;
}

.NoDelivery {
    color: #1b5e20;
    background-color: #c8e6c9;
    font-weight: normal;
}

.Row span,
.Row strong {
    text-align: left;
    padding: 0 0.5em;
}

.Explained {
    display: grid;
    color: #999;
    font-weight: 400;
}

.DialogWrp {
    display: grid;
    place-content: center start;
    place-items: center;
    margin: 4px 0;
    padding: 1em;
    border: 4px solid #999;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.DialogHeaderCell {
    font-weight: 700;
    font-size: 1.25em;
    text-align: left;
    padding-left: 8px;
}

.DialogCell {
    padding: 0 8px;
}

.DialogOrderEditor {
    display: grid;
    grid-gap: 0.5em;
    padding: 4px 0;
    grid-auto-flow: column;
}

.DialogOrderEditor * {
    touch-action: manipulation;
}

.DialogTitleContent {
    display: grid;
    place-content: space-between;
    grid-auto-flow: column;
    place-items: center;
}

.DialogOrderEditorButton {
    text-align: center;
    max-width: 50px !important;
    min-width: 40px !important;
    padding: 0;
}

.DialogOrderEditorButton button {
    padding: 0;
}

.DialogOrderEditorInput input {
    text-align: right;
    padding-right: 0.5em;
    font-weight: 700;
}

.DialogContent {
    display: grid;
    place-content: stretch;
    grid-gap: 0.5em;
}

.DialogActions {
    display: grid !important;
    place-content: space-between center;
    grid-auto-flow: column;
    grid-template-columns: min-content 1fr min-content;
}

.DialogEditorAdd {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 1em;
    padding: 1em;
}

.DialogOrderLabel {
    display: grid;
}

.DialogOrderLabelDiscount {
    font-size: 0.5em;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 400;
    background-color: #7cb342;
    color: white;
    padding: 2px 8px;
}

.DialogOrderLabelDiscount::before {
    content: 'DISCOUNT "';
}

.DialogOrderLabelDiscount::after {
    content: '"';
}

.DialogOrderLabelDiscount::before,
.DialogOrderLabelDiscount::after {
    font-weight: 600;
    font-style: normal;
}

.DialogAddVendor {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    place-content: center;
}

@media print {
    .Main {
        grid-template-rows: 50px 1fr;
    }

    .Content > * {
        min-height: 30px;
    }

    .Content {
        height: auto;
    }

    .Row {
        font-weight: normal;
        font-size: 12px;
        text-align: left;
    }

    .Row span,
    .Row strong {
        text-align: left;
    }
}

@media (max-width: 1000px) {
    .Header {
        padding: 0.25em 1em;
        width: calc(100vw - 36px);
    }

    .DialogTitleText {
        font-size: 0.75em;
        line-height: 1em;
    }

    .Content {
        width: calc(100vw - 36px);
    }

    .VendorContent {
        width: calc(100vw - 48px - 4em);
    }

    .DialogContent {
        padding: 0.5em !important;
    }

    .DialogWrp {
        padding: 4px !important;
    }

    .DialogWrp td {
        font-size: 0.7em;
        padding: 0 2px !important;
    }

    .DialogWrp td input {
        font-size: 0.9em;
    }

    .DialogOrderEditor button {
        padding: 0 !important;
        min-width: 30px !important;
    }
}

.Tab {
    display: grid;
    font-size: 0.9em !important;
    line-height: 1.5em !important;
    padding: 0 0.5em !important;
}

.Tab * {
    touch-action: manipulation;
}

@media (max-width: 1000px) {
    .Tab {
    }
}

.menusUploadsDropZones {
    display: grid;
    grid-auto-flow: column;
    padding: 0.5em 2em;
    grid-gap: 2em;
}

@media (max-width: 1000px) {
    .menusUploads {
        display: none !important;
    }
}

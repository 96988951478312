.Main {
    display: grid;
    grid-template-rows: 1fr 1px 3em;
    max-height: 100px !important;
    height: 100px !important;
    min-height: 100px !important;
}

.TakeBtnWrp {
    display: grid;
    place-content: center;
}

.InventoryPreview {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5em;
    place-content: center;
}

.StockNoStock {
    background-color: gray;
    color: white;
}

.StockPartial {
    background-color: red !important;
    color: white !important;
}

.StockSuccess {
    background-color: #8bc34a !important;
    color: white !important;
}

.StockingSelected > div {
    display: grid;
    grid-gap: 4px;
    grid-auto-flow: column;
    place-content: center;
}

.StockingSelected > div:last-child {
    color: #9e9e9e;
}

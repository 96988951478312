#root {
    height: 100vh; /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100); /* Mobile Layout Calculation */
    width: 100vw; /* Layout Calculation */
}

main {
    display: grid;
    height: calc(100vh - 36px); /* Layout Calculation */
    height: calc(var(--vh, 1vh) * 100 - 36px); /* Mobile Layout Calculation */
    width: calc(100vw - 48px); /* Layout Calculation */
}

main > * {
    touch-action: manipulation;
}

.truncate {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.do-print {
    display: none !important;
}

.SelectedTab {
    border: 2px solid !important;
    font-weight: 600 !important;
    min-height: 40px !important;
}

.DirtyTab {
    background: repeating-linear-gradient(45deg, #ffffff01, #ffffff01 10px, white 10px, white 20px);
}

.hidden {
    display: none !important;
}

.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

.sortable-selected {
    background-color: #aed581 !important;
    color: #33691e;
}

.handle {
    cursor: grab;
}

@media print {
    body,
    #root {
        height: auto;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }

    main {
        height: auto;
        background-color: white;
        width: calc(100vw - 36px); /* Layout Calculation */
    }

    .do-print {
        display: inherit !important;
    }
}

.menusDropZoneArea {
    display: grid;
}

.menusDropZoneTitle {
    font-size: 1.2em;
    color: #333;
    font-weight: 600;
}

@media (max-width: 1000px) {
    .menusDropZoneArea {
        padding: 0.5em !important;
    }

    .menusDropZoneArea > * {
        font-size: 0.8em;
    }
}

.Header {
    font-size: 1.2em;
    font-weight: 600;
    overflow-y: scroll;
    margin: 0 1px;
    grid-gap: 0 !important;
}

.Header > * {
    background-color: #3f51b5 !important;
    color: white;
    min-height: 1em !important;
    padding: 2px !important;
    display: grid !important;
    place-content: center;
}

.Header > .SizeSelected {
    background-color: #8bc34a !important;
}

@media (max-width: 1000px) {
    .Header {
        font-size: 0.7em;
        font-weight: normal;
    }

    .Header > * {
        min-height: 0.8em !important;
        padding: 1px !important;
    }
}
